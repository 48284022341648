.App {
  /* text-align: center; */
}

.main-layout{
  display: flex;
  background-color: #F5F5FE;
}
.sidebar{
  flex: 0 0 auto;
}
.main-page{
  flex: 1; 
  width: 100%;
}

.bold-title {
  font-family: "ClashDisplay";
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
  color: #000000;
}

.title {
  font-family: "ClashDisplay";
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 4%;
  color: #000000;
}

.sub-title {
  font-family: "ClashDisplay";
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 4%;
  color: #000000;
}

.btn-text {
  font-family: "ClashDisplay";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 4%;
  color: #000000;
}

.middle-title {
  font-family: "ClashDisplay";
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 4%;
}

.general-text {
  font-family: "ClashDisplay";
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 4%;
  color: #000000;
}

.small-text {
  font-family: "ClashDisplay";
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #000000;
}

.general-btn{
  background-color: #000000;
  padding: 7px 20px;
  outline: none;
  border: none;
  border-radius: 8px;
}
