.Analyzing-container{
  background-color: #FFFFFF;
  padding: 10px 30px;
}
.analyzing-integer{
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #F5F5FE;
}
.analyzing-integer-first{
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  margin-right: auto;
}
.analyzing-integer-second{
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}
.analyzing-main{
  width: 100%;
  height: 300px;
  text-align: center;
  align-content: center;
}
@media only screen and (max-width: 800px) {
  .Analyzing-container{
    padding: 10px 10px;
  }
}

.creator-Analyzing-container{
  background-color: #FFFFFF;
  padding: 30px 20px;
}
.creator-analyzing-header{
  display: flex;
}
.creator-analyzing-header-num{
  flex: 1;
  text-align: left;
}
.creator-analyzing-header-icon{
  text-align: right;
}
.creator-analyzing-content{
  margin-top: 10px;
}
.creator-table-handler{
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
}
.creator-table-search-form{
  width: 300px;
}
